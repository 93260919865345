.sound-configurator-screen {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #b62e0d;
    color: #fff;
    text-align: center;
    transition: transform 1s easeInOutCubic;
    overflow: hidden;
    overflow: clip;

    &.sound-configurator-screen--leaving {
        transform: translate3d(0, -100%, 0);

        .sound-configurator-screen-inner-el {
            transform: translate3d(0, 100%, 0);
        }
    }
}

.sound-configurator-screen-inner-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1s easeInOutCubic;
    padding: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1366px) {
        padding: 40px;
    }

    @media screen and (max-width: 767px) {
        padding: 30px var(--offset-x);
    }
}

.sound-configurator-screen__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sound-configurator-screen__text {
    max-width: 352px;
    font-size: 22px;
    letter-spacing: -0.01em;
    margin-bottom: 59px;
    line-height: 1.09;
    position: relative;
}

.sound-configurator-screen__actions {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @media screen and (min-width: 1025px) {
        gap: 10px 76px;
    }

    @media screen and (max-width: 1024px) {
        gap: 10px 55px;
    }

    @media screen and (max-width: 767px) {
        gap: 10px 40px;
    }
}

.sound-configurator-screen__action {
    width: 138px;

    @media screen and (max-width: 1024px) {
        width: 110px;
    }

    @media screen and (max-width: 767px) {
        width: 90px;
    }

    @include hover {
        .sound-on_svg__border,
        .sound-off_svg__border {
            opacity: 1;
        }
    }

    .sound-on_svg__border,
    .sound-off_svg__border {
        fill-opacity: 1;
        transition: opacity 0.2s ease;
        opacity: 0.2;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.progress-path-svg-wrapper {
    width: calc(100% + var(--offset-x) * 2);
    margin: auto calc(-1 * var(--offset-x));
}

.progress-path-svg {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;

    &.progress-path-svg--mobile {
        max-width: 350px;
        margin-bottom: calc(-24px - 34px);
    }
}

.progress-path__link {
    cursor: pointer;

    @include hover {
        .progress-path__link-circle {
            fill: #fff;
            opacity: 0.8;
        }
    }

    .menu.lit-popup--opened & {
        pointer-events: all;

        @media (hover: none) {
            pointer-events: bounding-box;
        }
    }

    &.progress-path__link--active {
        .progress-path__link-circle {
            opacity: 1;
        }
    }
}

.progress-path__link-circle {
    fill-opacity: 0.1;
    opacity: 0.2;
    transition: opacity 0.2s ease, fill 0.2s ease;
}

.header {
    position: fixed;
    z-index: 11;
    width: 100%;
    top: 0;
    height: var(--header-height);
    display: flex;
    align-items: center;
}

.logo {
    width: 56px;
    transition: opacity 0.2s ease;

    @media screen and (max-width: 767px) {
        width: 48px;
    }

    @include hover {
        opacity: 0.5;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.header__right {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.header-btn {
    &:not(:last-child) {
        margin-right: 10px;
    }
}

.sound-toggler-btn {
    svg {
        rect {
            transform-origin: 50% 100%;
            animation: sound-active 1s linear infinite;
            animation-play-state: paused;

            &:nth-child(2) {
                animation-delay: 0.1s;
            }

            &:nth-child(3) {
                animation-delay: 0.2s;
            }

            &:nth-child(4) {
                animation-delay: 0.3s;
            }
        }
    }

    &.sound-toggler-btn--enabled {
        svg {
            rect {
                animation-play-state: running;
            }
        }
    }
}

.menu-btn {
    flex-direction: column;
    position: relative;

    > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: currentColor;
        width: 20px;
        height: 2px;
        border-radius: 4px;
        transition: transform 0.3s easeOutCubic;

        &:nth-child(1) {
            transform: translate(-50%, -50%) translate(0, -3px);
        }

        &:nth-child(2) {
            transform: translate(-50%, -50%) translate(0, 3px);
        }
    }

    &.menu-btn--opened {
        > span {
            &:nth-child(1) {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:nth-child(2) {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}

:root {
    --header-height: 140px;
    --offset-x: 64px;

    @media screen and (max-width: 1366px) {
        --offset-x: 40px;
        --header-height: 110px;
    }

    @media screen and (max-width: 1024px) {
        --offset-x: 30px;
        --header-height: 90px;
    }

    @media screen and (max-width: 767px) {
        --offset-x: 16px;
        --header-height: 70px;
    }
}

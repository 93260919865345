.lit-popup {
    --leave-duration: 0.3s;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    contain: strict;

    &:not(.lit-popup--opened) {
        visibility: hidden;
    }

    &[data-lit-popup-preset='default'] {
        transition: opacity var(--leave-duration) ease,
            visibility var(--leave-duration) ease;

        &:not(.lit-popup--opened),
        &.lit-popup--closing {
            opacity: 0;
        }
    }
}

.lit-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.64;
}

.lit-popup-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    // @include scrollbar();
}

.light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    object-fit: cover;
    object-position: 50% 0%;
    opacity: 0.5;
    mix-blend-mode: screen;
    user-select: none;
}

.progress-round {
    --size: 64px;
    position: absolute;
    z-index: 1;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    width: var(--size);
    height: var(--size);

    @media screen and (max-width: 1366px) {
        --size: 54px;
        bottom: 30px;
    }

    @media screen and (max-width: 767px) {
        --size: 44px;
        bottom: 20px;
    }
}

.progress-round__el {
    overflow: visible;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
}

.progress-round__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: scroll-down 1.7s easeInOutCubic infinite;
    width: 16px;

    @media screen and (max-width: 1366px) {
        width: 14px;
    }

    @media screen and (max-width: 767px) {
        width: 12px;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.last-message {
    font-family: 'Golden Night', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    position: absolute;
    z-index: 1;
    top: 30%;
    right: 5%;
    transform: translate(0, -50%) rotate(-18deg);
    letter-spacing: -0.02em;
    font-size: 6.77vw;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    pointer-events: none;
    user-select: none;
    transition: opacity 0.3s ease, transform 0.6s easeOutCubic;

    @media screen and (max-width: 767px) {
        font-size: 60px;
        top: 19%;
        right: var(--offset-x);
    }

    &:not(.is-visible) {
        opacity: 0;
        transform: translate3d(30px, -50%, 0) rotate(-18deg);
    }

    > span {
        &:last-child {
            padding-left: 1.2em;
        }
    }
}

.letter-popup {
    background-color: #47acfc;
    color: $dark;

    .lit-popup-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: var(--header-height);
        padding-bottom: var(--header-height);

        @media screen and (max-width: 1920px) {
            padding-top: calc(var(--header-height) / 2);
            padding-bottom: calc(var(--header-height) / 2);
        }
    }

    &.lit-popup--opened {
        .letter__text,
        .letter__logo,
        .letter-happy,
        .letter-chipsa,
        .letter-bottom {
            opacity: 0;
            animation: fade-in 0.5s easeOutCubic forwards;
        }

        .letter__text {
            animation-delay: 0.4s;
        }

        .letter__logo {
            animation-delay: 0.55s;
        }

        .letter-happy {
            animation-delay: 0.7s;
        }

        .letter-bottom {
            animation-delay: 0.85s;
        }

        .letter-chipsa {
            animation-delay: 1s;
        }
    }
}

.letter-popup-content-wrapper {
    position: relative;
    width: calc(100% - 40px);
    height: auto;
    top: 0;
    left: 0;
    max-width: 1050px;
    background-color: #f1dfc1;
    border-radius: 4px;
    margin: auto;
}

.letter-popup__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
}

.letter-inner {
    --padding: 64px;
    padding: var(--padding);
    padding-right: calc(var(--padding) + 250px);
    display: flex;
    flex-direction: column;
    position: relative;

    @include mobile {
        --padding: 48px;
        padding-left: 30px;
        padding-right: 30px;
        padding-right: calc(var(--padding) + 100px);
    }
}

.letter__border {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

.letter__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
}

.letter__text {
    max-width: 475px;
    @include responsive-font-size(18px, 24px, 1025px, 1920px);
    line-height: 1.18;
    letter-spacing: -0.01em;
    padding-top: 120px;

    @media screen and (max-width: 1920px) {
        padding-top: 60px;
    }

    @media screen and (max-width: 1680px) {
        padding-top: 40px;
    }

    @include mobile {
        padding-top: 20px;
        @include responsive-font-size(11px, 18px, 360px, 768px);
    }
}

.letter__logo {
    --width: 156px;
    position: absolute;
    top: var(--padding);
    right: var(--padding);
    background-color: #efdec3;
    width: var(--width);
    height: calc(var(--width) * 1.33);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    @media screen and (max-width: 1680px) {
        --width: 135px;
    }

    @media screen and (max-width: 1024px) {
        --width: 112px;
        padding: 15px;
    }

    @media screen and (max-width: 767px) {
        --width: 84px;
        right: 30px;
    }
}

.letter__logo-border {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}

.letter-happy-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: -40px;

    @media screen and (max-width: 1024px) {
        margin-top: -20px;
    }

    @media screen and (max-width: 767px) {
        position: relative;
        left: 5%;
        margin-top: 10px;
    }
}

.letter-happy {
    width: 100%;
    max-width: 509px;
    user-select: none;

    @media screen and (max-width: 1680px) {
        max-width: 440px;
    }
}

.letter-chipsa {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 239px;
    user-select: none;

    @include mobile {
        bottom: calc(-1 * var(--padding));
        right: calc(-1 * var(--padding));
    }
}

.letter-bottom {
    display: flex;
    margin-top: auto;
    padding-top: 30px;
    gap: 33px;
    width: calc(100% + 250px);
    @include responsive-font-size(18px, 24px, 1025px, 1920px);

    @media screen and (max-width: 1024px) {
        width: calc(100% + 130px);
        @include responsive-font-size(11px, 18px, 360px, 768px);
    }

    @media screen and (max-width: 767px) {
        width: calc(100% + 100px);
        @include responsive-font-size(11px, 18px, 360px, 768px);
    }
}

.letter-bottom__item {
    display: flex;
    flex: 1 1 100%;

    &:last-child {
        margin-left: auto;
    }
}

.letter-bottom__item--underlined {
    border-bottom: 1px solid rgba($dark, 0.2);
    margin-left: 0.3em;
    flex: 1 1 auto;
}

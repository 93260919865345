html {
    overscroll-behavior: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: clip;
}

body {
    font-family: 'Stix Two Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    min-height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
    overflow: hidden;
    overflow: clip;
}

* {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.wrapper {
    display: block;
    width: 100%;
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--offset-x);
    padding-right: var(--offset-x);
}

#root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1 1 auto;
    overflow: hidden;
    overflow: clip;
}

.canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    overflow: hidden;
    overflow: clip;
}

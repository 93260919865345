@keyframes sound-active {
    0%,
    100% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.25);
    }
}

@keyframes scroll-down {
    0%,
    100% {
        transform: translate3d(-50%, calc(-50% - 3px), 0);
    }

    40% {
        transform: translate3d(-50%, calc(-50% + 3px), 0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.round-btn {
    --size: 48px;
    width: var(--size);
    height: var(--size);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid rgba($dark, 0.2);
    padding: 10px;
    transition: background-color 0.2s ease;

    @include hover {
        background-color: rgba($dark, 0.2);
    }

    @include mobile {
        --size: 32px;
    }
}

.round-btn-lg {
    --size: 56px;

    @include mobile {
        --size: 40px;
    }
}

.message {
    --width: 750px;
    --width: Min(750px, 100vw);
    max-width: 100vw;
    width: var(--width);
    position: absolute;
    z-index: 1;
    min-height: calc(var(--width) * 0.608);
    padding-top: calc(var(--width) / 100 * 17.3);
    padding-bottom: calc(var(--width) / 100 * 9.33);
    padding-left: calc(var(--width) / 100 * 19.66);
    padding-right: calc(var(--width) / 100 * 13.33);
    user-select: none;
    pointer-events: none;
    transition: opacity 0.3s ease, transform 0.6s easeOutCubic;

    @media screen and (max-width: 1680px) {
        --width: 600px;
        --width: Min(600px, 100vw);
    }

    @media screen and (max-width: 576px) {
        --width: 100vw;
        padding-left: calc(var(--width) / 100 * 12.66);
    }

    &:not(.is-visible) {
        opacity: 0;
        transform: translate3d(30px, 0, 0);
    }

    &.message-orange {
        top: 0%;
        right: 0%;

        @media screen and (max-width: 767px) {
            top: 5%;
        }
    }

    &.message-orange-small {
        --width: 200px;
        padding: 0;
        bottom: 50px;
        left: 50%;
        max-width: 100%;
        transform: translate(-50%, 0);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (hover: hover) {
            display: none;
        }

        .message__title {
            &:empty {
                display: none;
            }
        }

        .message__text {
            margin: 0;
            font-size: 11px;
        }
    }

    &.message-blue {
        bottom: 0%;
        right: 0%;

        @media screen and (max-width: 767px) {
            bottom: 50px;
        }
    }

    &.message-orange-2 {
        bottom: 10%;
        right: 0%;

        @media screen and (max-width: 767px) {
            bottom: 60px;
        }
    }

    &.message-red {
        top: 1%;
        right: 1%;

        @media screen and (max-width: 767px) {
            right: 0%;
        }
    }

    &.message-green {
        top: 0%;
        right: 0%;

        @media screen and (max-width: 767px) {
            top: 5%;
        }
    }
}

.message__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.message__title {
    font-family: 'Golden Night', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    // font-size: 52px;
    font-size: calc(var(--width) / 100 * 6.93);
    // @include responsive-font-size();
    letter-spacing: -0.015em;
    transform: rotate(-4deg);
    position: relative;
    line-height: 1;
    padding-left: 50px;
}

.message__text {
    // font-size: 22px;
    font-size: calc(var(--width) / 100 * 2.93);
    letter-spacing: -0.01em;
    position: relative;
    max-width: 353px;
    margin-left: 90px;
    line-height: 1.09;
    margin-top: 20px;
    margin-right: 10px;
}

.preloader {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ed8104;
    color: #fff;
    transition: transform 1s easeInOutCubic;
    overflow: hidden;
    overflow: clip;

    &.preloader--leaving {
        transform: translate3d(0, -100%, 0);

        .preloader-inner-el {
            transform: translate3d(0, 100%, 0);
        }
    }
}

.preloader-inner-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1s easeInOutCubic;
    padding: 80px;
    display: flex;
    flex-direction: column;
}

.preloader-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.preloader-img {
    width: 100%;
    max-width: 711px;
    margin: auto;
    position: relative;
    object-fit: contain;

    @media screen and (max-width: 1680px) {
        max-width: 500px;
    }
}

.preloader-bottom {
    margin-top: 80px;
    max-width: 906px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media screen and (max-width: 1680px) {
        margin-top: 50px;
    }
}

.preloader-progress {
    font-size: 24px;
    letter-spacing: -0.01em;
    margin-bottom: 21px;
}

.preloader-progressbar {
    width: 100%;
    position: relative;
    height: 2px;
    background-color: rgba(#fff, 0.2);
}

.preloader-progressbar__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform-origin: 0% 50%;
    transition: transform 1s ease;
    will-change: transform;
}

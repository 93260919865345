.menu {
    background-color: #b62e0d;
    color: #fff;

    .lit-popup-container {
        display: flex;
        flex-direction: column;
    }
}

.menu__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 34px;
}

.menu-bottom {
    display: flex;
}

.menu-bottom__left {
    padding-right: 30px;
}

.menu-bottom__right {
    margin-left: auto;
}
